<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item v-if="mode=='add'" :colon="false" label=" " :labelCol="labelCol" :wrapperCol="wrapperCol">
          <div style="text-align: right"><a @click="showDataSearchModle">从地址库选择</a></div>
        </a-form-item>
        <a-form-item label="地址名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
              allowClear
              placeholder="请输入地址名称"
              v-decorator="['address_name', {rules: [{required: true, message: '请输入地址名称'}]}]"
          />
        </a-form-item>
        <a-form-item label="详细地址" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-search
              placeholder="详细地址"
              v-decorator="['address_detail', {rules: [{required: true, message: '请选择详细地址'}]}]"
              @search="handleShowMap"
          >
            <a-button slot="enterButton">
              选择位置
            </a-button>
          </a-input-search>
        </a-form-item>

        <a-form-item label="经度" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
              allowClear
              placeholder="请输入经度"
              v-decorator="['lat', {rules: [{required: true, message: '请输入经度'}]}]"
          />
        </a-form-item>

        <a-form-item label="纬度" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
              allowClear
              placeholder="请输入纬度"
              v-decorator="['lng', {rules: [{required: true, message: '请输入纬度'}]}]"
          />
        </a-form-item>

        <a-form-item label="启用状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-radio-group
              v-decorator="['status', { initialValue: 0,rules: [{ required: true }] }]"
          >
            <a-radio :value="0">启用</a-radio>
            <a-radio :value="1">停用</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-spin>

    <ChooseMap ref="ChooseMap" @handleSuccess="mapSuccess"/>
    <address-search-model ref="addressSearchModel" @handleSuccess="setFormData"/>
  </a-modal>
</template>

<script>

import * as Api from '@/api/data/address'

import _ from "lodash";

import {AddressSearchModel, ChooseMap} from '@/components'

export default {
  components: {
    ChooseMap,
    AddressSearchModel
  },
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: "",

    }
  },
  methods: {
    showDataSearchModle() {
      this.$refs.addressSearchModel.open()
    },

    setFormData(item) {
      this.form.setFieldsValue({
        address_name: item.address_name,
        address_detail: item.address_detail,
        lat: item.lat,
        lng: item.lng,
      })
    },

    handleShowMap() {
      this.$refs.ChooseMap.open(this.record)
    },
    mapSuccess(item) {
      const formData = {
        address_name: item.title,
        address_detail: item.address,
        lat: item.location.lat,
        lng: item.location.lng
      }
      this.form.setFieldsValue(formData)
    },
    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '新增' + this.$route.meta.title

      this.visible = true
    },
    edit(record) {
      this.mode = "edit"
      this.title = '编辑' + this.$route.meta.title
      this.visible = true

      this.record = record
      this.setFieldsValue()
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['address_name', 'address_detail', 'lat', 'lng', 'yhp_type', 'status'])
        setFieldsValue(data)
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({addressId: this.record['address_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
